import React from "react"
import SEO from "../components/seo"
import { Block } from "../components/Block"
import { H1, Text } from "../components/Text"

const browser = typeof window !== "undefined" && window

const NotFoundPage = () =>
  browser && (
    <>
      <SEO title="404: Not found" />
      <Block>
        <H1>NOT FOUND</H1>
        <Text>You just hit a route that doesn&#39;t exist... the sadness.</Text>
      </Block>
    </>
  )

export default NotFoundPage
